<template>
  <div id="footer" class="flex-none hidden md:block">
    <!-- Start Bottom Desktop -->
    <div class="w-auto h-auto !bg-opacity-90 !backdrop-blur-lg">
      <div class="relative flex justify-between items-center">
        <!-- Start Bottom Left -->
        <div class="flex items-center">
          <!-- Reduce max-width by the width of the UI elements bottom right to avoid collision -->
          <div class="absolute top-0 left-0 !pl-6 bg-white z-10">
            <UploadButton @click="uploadFile" class="!m-2 !mb-0 upload-btn sm:h-16 sm:w-16" />
          </div>

          <div class="overflow-x-scroll overflow-auto" style="max-width: calc(100vw - 350px)">
            <div class="flex gap-2 items-center pl-28 -ml-1.5 pt-2 pb-4">
              <div v-for="image in store.images" :key="image.meta.id" @click="store.selectImage(image)">
                <ImageButton
                  v-if="
                    image.previewResult.state === ProcessingState.Error || image.original.state === UploadState.Error
                  "
                  :selected="store.selectedImage === image"
                  class="relative"
                >
                  <div class="bg-typo text-white flex justify-center items-center bg-opacity-80 absolute w-full h-full">
                    <WarningIcon />
                  </div>
                  <HTMLImage
                    :src="image.meta.preview"
                    img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
                  />
                </ImageButton>

                <ImageButton
                  v-else-if="image.previewResult.state === ProcessingState.Processing"
                  :selected="store.selectedImage === image"
                  class="relative"
                >
                  <div class="bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full">
                    <LoadingIcon class="text-white animate-spin" />
                  </div>
                  <HTMLImage
                    :src="image.meta.preview"
                    img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
                  />
                </ImageButton>

                <ImageButton v-else :selected="store.selectedImage === image">
                  <HTMLImage
                    :src="previewUrl(image)"
                    img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
                  />
                </ImageButton>
              </div>

              <!-- Empty element to have some more space for horizontal scrolling -->
              <div class="block shrink-0 !w-12 !h-12 sm:!w-16 sm:!h-16 pointer-events-none"></div>
            </div>
          </div>
        </div>
        <!-- End Bottom Left -->
        <!-- Start Bottom Right -->
        <div class="flex gap-2 items-center px-6 py-4 rounded-2xl">
          <!-- Start Rating -->
          <div class="flex items-center mr-2">
            <Rating :image="store.selectedImage" />
          </div>
          <!-- End Rating -->

          <!-- Start Divider -->
          <span class="h-4 w-[1px] rounded-full bg-secondary-hover"></span>
          <!-- End Divider -->

          <!-- Trash Icon / Delete Image -->
          <IconButton
            v-if="!requiresDeleteConfirmation"
            variant="secondary"
            :title="I18n.t('editor.delete_selected')"
            @click.prevent="() => deleteImage(store.selectedImage)"
          >
            <TrashIcon />
          </IconButton>
          <Dialog v-else trigger-classes="focus:outline-none">
            <template #trigger>
              <IconButton variant="secondary">
                <TrashIcon />
              </IconButton>
            </template>
            <template v-slot:content="{ closeDialog }">
              <div class="flex flex-col gap-y-6 w-full max-h-[680px]">
                <Heading level="h4">{{ I18n.t("editor.are_you_sure") }}</Heading>
                <p>{{ I18n.t("editor.you_purchased") }}</p>
                <div class="sm:flex sm:flex-row-reverse sm:gap-x-3 space-y-3 sm:space-y-0 grow">
                  <Button
                    fullWidth
                    class="mb-2"
                    type="submit"
                    @click.prevent="() => deleteImage(store.selectedImage, closeDialog)"
                    >{{ I18n.t("editor.delete") }}</Button
                  >
                  <Button fullWidth autofocus variant="secondary" type="reset" @click="closeDialog">{{
                    I18n.t("editor.cancel")
                  }}</Button>
                </div>
              </div>
            </template>
          </Dialog>
        </div>
        <!-- End Bottom Right -->
      </div>
    </div>
    <!-- End Bottom Desktop -->
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  Image as HTMLImage,
  ImageButton,
  WarningIcon,
  TrashIcon,
  LoadingIcon,
  IconButton,
  UploadButton,
  Dialog,
  Heading,
  Button,
} from "prism";
import { Image, UploadState } from "@/modules/internal_api/image";
import { ProcessingState } from "@/modules/internal_api/image";
import { rbgRemoveImageV100 } from "kaleido-event-tracker";
import Rating from "@/components/prism/rating.vue";
import originalPlaceholder from "@assets/images/inline-editor/original_placeholder.jpg";

import Client from "@/modules/internal_api/client";

import { useEditorStore } from "@/stores/editor_store";
const store = useEditorStore();

const requiresDeleteConfirmation = computed(() => {
  const isHdResultPresent = store.selectedImage?.hdResult !== undefined;
  const isHdResultReady = store.selectedImage?.hdResult?.state === ProcessingState.Ready;

  return isHdResultPresent && !isHdResultReady;
});

const uploadFile = () => {
  window.onUploadFile();
};

const previewUrl = (image: Image) => {
  if (image.meta.preview === originalPlaceholder && image.original.url) return image.original.url;
  return image.meta.preview;
};

const deleteImage = (image: Image, closeDialog?: () => void) => {
  Client.deleteImage(image);
  store.deleteImage(image);
  if (closeDialog) closeDialog();
  window.track("Images", "remove_result", "Remove result");
  rbgRemoveImageV100({ image_id: image.meta.id });
};
</script>
