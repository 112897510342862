<template>
  <div class="max-w-md m-auto">
    <div class="flex flex-col justify-center items-center gap-6 mx-1 sm:mx-6 py-2">
      <Star class="text-brand sm:translate-x-48 sm:translate-y-6 scale-75" />
      <Heading align-center v-html="I18n.t('upload_widget.upload_page_heading_html')"></Heading>
      <Button size="lg" @click="uploadFile">{{ I18n.t("upload_widget.upload_image") }}</Button>

      <div class="hidden sm:flex flex-col gap-1.5">
        <p class="font-bold text-xl text-typo-secondary">{{ I18n.t("upload_widget.or_drop_a_file") }},</p>
        <span class="text-xs text-typo-secondary">
          {{ I18n.t("upload_widget.paste_text_start").toLowerCase() }}
          <a href="#" class="text-typo-secondary select-photo-url-btn underline" @click.prevent="uploadUrl(event)">{{ I18n.t("upload_widget.paste_text_url") }}</a>
          {{ I18n.t("upload_widget.paste_text_end") }}
        </span>
      </div>
      <div>
        <div class="max-w-md mt-12">
          <div class="flex flex-col gap-2 justify-between items-center">
            <div class="flex flex-row sm:!flex-col sm:justify-center">
              <span class="font-bold text-typo-secondary text-center">{{ I18n.t("upload_widget.no_image") }} {{ I18n.t("upload_widget.try_one_of_these") }}:</span>
            </div>
            <div class="flex gap-1">
              <ImageButton v-for="image in exampleImages" @click.prevent="exampleSelected(image)" :key="image.thumbnail_url" ondragstart="return false;">
                <Image :src="image.thumbnail_url" alt="Example image"></Image>
              </ImageButton>
            </div>
          </div>
          <p v-html="uploadTerms" class="text-2xs text-typo-secondary !mt-4 text-center"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, onMounted } from "vue";
import { Heading, Button, ImageButton, Image, Star } from "prism";
import { useUploaderSamples } from "@/composables/uploader_samples";
import type { I18n } from "i18n-js";

const I18n: I18n = inject("I18n");
const exampleImages = ref([]);
const uploadTerms = ref("");

const base = "https://static.remove.bg/uploader-samples/";
const { availableImages } = useUploaderSamples();

onMounted(() => {
  availableImages.forEach((element) => {
    const n = Math.floor(Math.random() * element.length);
    exampleImages.value.push(element[n]);
  });

  // TODO Improve this
  const metaElement = document.querySelector("meta[name='upload-page:t']");
  const metaContent = JSON.parse(metaElement.getAttribute("content"));
  uploadTerms.value = metaContent.upload_terms_prism;
});

const exampleSelected = (image) => {
  window.onExampleSelected(image);
};

const uploadFile = () => {
  window.onUploadFile();
};

const uploadUrl = (url) => {
  window.onUploadUrl(url);
};
</script>
