<template>
  <div class="grid-wrapper" >
    <Grid :columns="gridColumn" class="!p-4 gap-0 lg:gap-0">
      <TeaserCard
        v-for="menuItem in props.menuItems"
        :key="menuItem.title"
        :href="menuItem.href"
        isCompact
        :showArrow="false"
        :imageUrl="menuItem.thumbnail_image"
      >
        <template #title>{{ menuItem.title }}</template>
        <template #description><span>{{ menuItem.short_description }}</span></template>
      </TeaserCard>
    </Grid>
    <div class="flex-grow-1"><div class="border !border-secondary"></div></div>
    <Grid :columns="gridColumn" class="!p-4 gap-0 md:gap-0">
      <CardBase v-for="item in subMenuItems" variant="ghost" as="link" class="!p-3 group">
        <Link
          :href="item.href"
          class="flex gap-2 underline-none !text-base"
          :class="{ 'text-primary': item.isPrimary }"
          size="lg"
          :hide-arrow="!item.showArrow"
        >
          <component v-if="!!item.icon" :is="item.icon" />
          {{ item.title }}
        </Link>
      </CardBase>
    </Grid>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { Grid, CardBase, TeaserCard, Link } from "prism";
import { onMounted, ref, computed, onUnmounted } from "vue";

interface Menu {
  title: string,
  short_description?: string;
  icon?: any; // TODO: find the type of "Vue component" and replace "any" here.
  href?: string;
  thumbnail_image?: string;
  showArrow?: boolean;
  isPrimary?: boolean;
}

interface DropdownProps {
  menuItems: Array<Menu>;
  subMenuItems: Array<Menu>;
}

const props = defineProps<DropdownProps>();

onMounted(() => {
  updateNavLayout();
  window.addEventListener("resize", updateNavLayout);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateNavLayout);
});


// The following 3xl screen size value is greater than tailwind's default 2xl screen size by 256px;
const SCREEN_3XL = 1792;
const gridColumn = ref(1);
const updateNavLayout = () => {
  gridColumn.value = window.innerWidth > SCREEN_3XL ? 3 : 1;
}

</script>

<style>
.grid-wrapper {
  width: 384px;
}

@media screen and (min-width: 1792px) {
  .grid-wrapper {
    width: 1080px;
  }
}
</style>
