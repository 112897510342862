import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "../stores/plugins/plugin-persisted-state";

import {
  Avatar,
  Badge,
  Button,
  Container,
  Grid,
  Heading,
  Subheading,
  Image,
  Video,
  Link,
  LogoKaleidoSmall,
  LogoRBG,
  NavLink,
  Spinner,
  SubNavLink,
  Toggle,
  Accordion,
  AccordionGroup,
  Alert,
  BurgerMenu,
  Dropdown,
  DropdownBox,
  Nav,
  ImageDragger,
  TwoColContent,
  NavLogo,
  IconButton,
  Dialog,
  Card,
  ScrollingContainer,
  KitchenSink,
  Spacer,
  BasicInput,
  TabButton,
  SelectableCard,
  WaveSection,
  FrameHeartIcon,
  CameraIcon,
  CommentsOffIcon,
  EmbedIcon,
  MarketplaceIcon,
  DesktopIcon,
  EmojiTravelIcon,
  OfficeIcon,
  LikeOffIcon,
  GlobeIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  Curve,
  CurveSmall,
  Dot,
  Star,
  StarMedium,
  StarSmall,
  Triangle,
  Spring,
  WigglyLine,
  BrushIcon,
  TiktokIcon,
  InfoSmallIcon,
  SelectInput,
  TextArea,
  LinkedinIcon,
  YoutubeIcon,
} from "prism";

import Navbar from "../components/prism/navbar.vue";
import LanguagePicker from "../components/prism/language_picker.vue";
import Testimonials from "../components/prism/testimonials.vue";
import AutoplayImageDragger from "../components/prism/autoplay_image_dragger.vue";
import UploadWidget from "../components/prism/upload_widget.vue";
import UploadBox from "../components/prism/upload_box.vue";
import StickyButton from "../components/prism/sticky_button.vue";
import NewsletterOptin from "../components/prism/newsletter_optin.vue";
import Inspiration from "../components/prism/inspiration.vue";
import Comparison from "../components/prism/comparison.vue";
import LecBanner from "../components/prism/lec_banner.vue";

import GetInTouchForm from "../components/get_in_touch_form.vue";

// Vue 3 Components
import Auth from "../components/auth.vue";

export default () => {
  const app = createApp({
    name: "Prism",
  });
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);

  app.component("p-avatar", Avatar);
  app.component("p-badge", Badge);
  app.component("p-button", Button);
  app.component("p-container", Container);
  app.component("p-grid", Grid);
  app.component("p-heading", Heading);
  app.component("p-subheading", Subheading);

  app.component("p-image", Image);
  app.component("p-video", Video);
  app.component("p-link", Link);
  app.component("p-logo-kaleido-small", LogoKaleidoSmall);
  app.component("p-logo-rbg", LogoRBG);
  app.component("p-nav-link", NavLink);
  app.component("p-spinner", Spinner);
  app.component("p-sub-nav-link", SubNavLink);
  app.component("p-toggle", Toggle);
  app.component("p-accordion", Accordion);
  app.component("p-accordion-group", AccordionGroup);
  app.component("p-alert", Alert);
  app.component("p-burger-menu", BurgerMenu);
  app.component("p-dropdown", Dropdown);
  app.component("p-dropdown-box", DropdownBox);
  app.component("p-nav", Nav);
  app.component("p-two-col-content", TwoColContent);
  app.component("p-nav-logo", NavLogo);
  app.component("p-icon-button", IconButton);
  app.component("p-selectable-card", SelectableCard);
  app.component("p-wave-section", WaveSection);
  app.component("p-dialog", Dialog);
  app.component("p-card", Card);
  app.component("p-scrolling-container", ScrollingContainer);
  app.component("p-kitchen-sink", KitchenSink);
  app.component("p-spacer", Spacer);
  app.component("p-image-dragger", ImageDragger);

  app.component("p-basic-input", BasicInput);
  app.component("p-select-input", SelectInput);
  app.component("p-textarea", TextArea);

  app.component("p-frame-heart-icon", FrameHeartIcon);
  app.component("p-camera-icon", CameraIcon);
  app.component("p-comments-off-icon", CommentsOffIcon);
  app.component("p-embed-icon", EmbedIcon);
  app.component("p-marketplace-icon", MarketplaceIcon);
  app.component("p-desktop-icon", DesktopIcon);
  app.component("p-emoji-travel-icon", EmojiTravelIcon);
  app.component("p-office-icon", OfficeIcon);
  app.component("p-like-off-icon", LikeOffIcon);
  app.component("p-globe-icon", GlobeIcon);
  app.component("p-facebook-icon", FacebookIcon);
  app.component("p-instagram-icon", InstagramIcon);
  app.component("p-tiktok-icon", TiktokIcon);
  app.component("p-twitter-icon", TwitterIcon);
  app.component("p-linkedin-icon", LinkedinIcon);
  app.component("p-youtube-icon", YoutubeIcon);
  app.component("p-info-small-icon", InfoSmallIcon);

  app.component("p-curve", Curve);
  app.component("p-curve-small", CurveSmall);
  app.component("p-dot", Dot);
  app.component("p-star", Star);
  app.component("p-star-medium", StarMedium);
  app.component("p-star-small", StarSmall);
  app.component("p-triangle", Triangle);
  app.component("p-spring", Spring);
  app.component("p-wiggly-line", WigglyLine);
  app.component("p-brush-icon", BrushIcon);

  // RGB componentrs
  app.component("navbar", Navbar);
  app.component("language-picker", LanguagePicker);
  app.component("testimonials", Testimonials);
  app.component("autoplay-image-dragger", AutoplayImageDragger);
  app.component("upload-widget", UploadWidget);
  app.component("upload-box", UploadBox);
  app.component("sticky-button", StickyButton);
  app.component("newsletter-optin", NewsletterOptin);
  app.component("inspiration", Inspiration);
  app.component("comparison", Comparison);
  app.component("get-in-touch-form", GetInTouchForm);
  app.component("lec-banner", LecBanner);

  // Vue 3 Components
  app.component("auth", Auth);

  app.provide("I18n", window.I18n);
  app.mount("#app");
};
