<template>
  <ColorTile :onClick="select" :selected="isSelected(null)" class="border-2" :color="null">
    <div class="bg-white flex justify-center items-center !w-full !h-full">
      <DoNotEnterIcon />
    </div>
  </ColorTile>
  <ColorTile :selected="nativeColorPickerSelected" :onClick="select">
    <NativeColorPicker @color-picker-changed="select"></NativeColorPicker>
  </ColorTile>
  <ColorTile class="border-2" color="#ffffff" :onClick="select" :selected="isSelected('#ffffff')">
    <div class="!w-full !h-full bg-white" />
  </ColorTile>
  <ColorTile v-for="color in props.colors" :color="color" :onClick="select" :selected="isSelected(color)">
    <div class="!w-full !h-full" :style="{ backgroundColor: color }" />
  </ColorTile>
</template>

<script setup lang="ts">
import ColorTile from "../prism/color_tile.vue";
import NativeColorPicker from "../prism/native_color_picker.vue";
import { DoNotEnterIcon } from "prism";
import { computed } from "vue";
import { useEditorStore } from "@/stores/editor_store";
import { rbgEditorApplyBackgroundColorV102 } from "kaleido-event-tracker";

export interface ColorTilesProps {
  selectedImageState: any;
  colors: string[];
}

const store = useEditorStore();

// native color picker tile is only selected if there is a color set that is on in the colorList
const nativeColorPickerSelected = computed(() => {
  if (props.colors.includes(props.selectedImageState?.selectedBackgroundColor)) return false;
  if (props.selectedImageState?.selectedBackgroundColor == "#ffffff") return false;
  if (props.selectedImageState?.selectedBackgroundColor) return true;
});

const props = defineProps<ColorTilesProps>();

const select = (color) => {
  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setSelectedBackgroundPhotoUrl(undefined);
    props.selectedImageState.setBackgroundBlurEnabled(false);
    props.selectedImageState.setSelectedBackgroundColor(color);
  });

  rbgEditorApplyBackgroundColorV102({ image_id: store.selectedImage.meta.id, color_value: color });
};

const isSelected = (color) => {
  return props.selectedImageState.selectedBackgroundColor === color;
};
</script>

<style>
input[type="color"] {
  opacity: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
}
#color-picker-wrapper {
  float: left;
}
</style>
