<template>
  <p-image-dragger ref="dragger" :value="startValue">
    <template #front>
      <p-image :src="frontSrc" :on-load="imageLoaded" alt="Example image" />
    </template>
    <template #back>
      <p-image :src="backSrc" :on-load="imageLoaded" alt="Example image" />
    </template>
  </p-image-dragger>
</template>

<script setup>
import { ref } from "vue";

const startValue = ref(100);
const endValue = ref(50);
const duration = ref(2000);
const dragger = ref();
const imagesLoaded = ref(0);

const props = defineProps({
  frontSrc: String,
  backSrc: String,
});

const imageLoaded = () => {
  imagesLoaded.value += 1;
  if (imagesLoaded.value < 2) return;

  if (!dragger.value) return;
  dragger.value.transition(startValue.value, endValue.value, duration.value);
};
</script>
