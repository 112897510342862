<template>
  <Image :src="colorTileImage" alt="Colorpicker" img-classes="object-cover !w-16 !h-16 bg-white" class="float-left" />
  <input type="color" v-model="color" class="absolute !w-16 !h-16 cursor-pointer" />
</template>

<script setup lang="ts">
import colorTileImage from "@public/images/colorTile.png";
import { Image } from "prism";
import { ref, watch } from "vue";

const color = ref("#0000FF");

const emit = defineEmits<{
  (e: "colorPickerChanged", color: string): void;
}>();

watch(color, (color) => {
  emit("colorPickerChanged", color);
});
</script>

<style lang="css" scoped>
input[type="color"] {
  opacity: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
}
#color-picker-wrapper {
  float: left;
}
</style>
