<template v-if="props.selectedImageState?.photoList">
  <ImageButton
    :small-on-mobile="false"
    :elevate-on-hover="false"
    @click.prevent="select(null)"
    :selected="isSelected(null)"
  >
    <div class="bg-white flex justify-center items-center !w-full !h-full">
      <DoNotEnterIcon />
    </div>
  </ImageButton>

  <!-- uploaded background -->
  <ImageButton
    v-if="store.isUploadingCustomBackground"
    :selected="true"
    :elevate-on-hover="false"
    :small-on-mobile="false"
  >
    <div class="bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full">
      <LoadingIcon class="text-white animate-spin" />
    </div>
  </ImageButton>

  <ImageButton
    v-else-if="props.selectedImageState?.uploadedBackgroundPhotoUrl"
    :elevate-on-hover="false"
    :small-on-mobile="false"
    :selected="isSelected(props.selectedImageState?.uploadedBackgroundPhotoUrl)"
    @click.prevent="
      selectOrDelete(
        props.selectedImageState?.uploadedBackgroundPhotoUrl,
        isSelected(props.selectedImageState?.uploadedBackgroundPhotoUrl)
      )
    "
    :key="props.selectedImageState?.uploadedBackgroundPhotoUrl"
  >
    <div
      v-if="isSelected(props.selectedImageState?.uploadedBackgroundPhotoUrl)"
      class="bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full"
    >
      <TrashIcon class="text-white" />
    </div>
    <Image
      :src="props.selectedImageState?.uploadedBackgroundPhotoUrl"
      alt="Uploaded image"
      img-classes="object-cover !w-16 !h-16 bg-white checkerboard"
    />
  </ImageButton>

  <ImageButton v-else :small-on-mobile="false" :elevate-on-hover="false" @click="uploadFile">
    <div class="!bg-white !hover:bg-secondary h-full flex justify-center items-center">
      <AddIcon />
    </div>
  </ImageButton>

  <!-- original background -->
  <ImageButton
    :elevate-on-hover="false"
    :small-on-mobile="false"
    :selected="isSelected(originalUrl(store.selectedImage))"
    @click.prevent="select(originalUrl(store.selectedImage))"
    :key="originalUrl(store.selectedImage)"
  >
    <Image
      :src="originalUrl(store.selectedImage)"
      alt="Original image"
      img-classes="object-cover !w-16 !h-16 bg-white checkerboard"
    />
  </ImageButton>

  <template v-if="props.isLoading">
    <div v-for="i in 32" :key="i" class="!h-16 !w-16 !rounded-lg !bg-secondary animate-pulse"></div>
  </template>

  <ImageButton
    v-else
    :elevate-on-hover="false"
    :small-on-mobile="false"
    v-for="photo in props.availableBackgrounds"
    :selected="isSelected(photo.image_url)"
    @click.prevent="select(photo.image_url)"
    :key="photo.thumbnail_url"
  >
    <Image
      :src="photo.thumbnail_url"
      alt="Example image"
      img-classes="object-cover !w-16 !h-16 bg-white checkerboard"
    />
    <div
      v-if="isSelectedImageLoading(photo.image_url)"
      class="top-0 bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full"
    >
      <LoadingIcon class="text-white animate-spin" />
    </div>
  </ImageButton>
</template>

<script setup lang="ts">
import { Image, ImageButton, AddIcon, TrashIcon, LoadingIcon, DoNotEnterIcon } from "prism";
import { Background, originalUrl } from "@/modules/internal_api/image";
import { useEditorStore } from "@/stores/editor_store";
import { rbgEditorApplyBackgroundImageV101 } from "kaleido-event-tracker";
import { PersistentStore } from "@/stores/persistent_store";

export interface PhotoTilesProps {
  isLoading: boolean;
  selectedImageState: PersistentStore;
  availableBackgrounds: Background[];
}
const props = defineProps<PhotoTilesProps>();

const store = useEditorStore();

const select = (image_url: string) => {
  if (store.isApplyingBackgroundChanges) return;

  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setSelectedBackgroundColor(undefined);
    props.selectedImageState.setSelectedBackgroundPhotoUrl(image_url);
    props.selectedImageState.setIsBackgroundAddedByBlur(false);
  });

  rbgEditorApplyBackgroundImageV101({ image_id: store.selectedImage.meta.id, background_image_id: image_url });
};

const selectOrDelete = (image_url: string, isSelected: boolean) => {
  if (store.isApplyingBackgroundChanges) return;

  if (isSelected) {
    props.selectedImageState.withSnapshot(() => {
      props.selectedImageState.setUploadedBackgroundPhotoUrl(undefined);
      props.selectedImageState.setSelectedBackgroundColor(undefined);
      props.selectedImageState.setSelectedBackgroundPhotoUrl(undefined);
    });
  } else {
    select(image_url);
  }
};

const isSelected = (image_url) => {
  return props.selectedImageState.selectedBackgroundPhotoUrl === image_url;
};

const isSelectedImageLoading = (image_url: string) =>
  isSelected(image_url) && props.selectedImageState.selectedBackgroundLoading;

const uploadFile = () => {
  window.onUploadBackgroundFile();
};
</script>
